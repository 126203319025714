<template>
    <component :is="component" :to="to ?? link" v-if="to || link" class="beet-link" v-reveal>
        <span class="label" v-if="label">{{ label }}</span>
        <Icon :class="icon ?? 'arrow'" :id="icon ?? 'arrow'" />
    </component>
</template>

<script>
import {LangRouterLink} from '@/components/global'
import {RouterLink} from 'vue-router'

export default {
    name: 'SiteLink',
    props: {
        to: {
            type: Object,
            default: null
        },
        label: {
            type: String,
            required: true
        },
        link: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        }
    },
    computed: {
        component() {
            return this.link ? RouterLink : LangRouterLink
        }
    }
}
</script>