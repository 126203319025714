export default {
    about: 'À propos',
    beet: {
        credits: 'Site web conçu et développé par'
    },
    browser_support: 'Votre navigateur ne semble pas supporter ceci.',
    featured_projects_1: 'Voir tous les projets ',
    featured_projects_2: '',
    footer: {
        talk_to_expert: 'Parlez à un expert',
        follow_us: 'Nous suivre',
        facebook: 'Lien Facebook',
        instagram: 'Lien Instagram',
        linkedin: 'Lien LinkedIn',
        vimeo: 'Lien Vimeo',
        rights: '©[YEAR] Beet Productions. Tous droits réservés. ',
        terms_and_conditions: 'Conditions générales',
        dev: 'Site web conçu et développé par '
    },
    full_video: 'Jouer la vidéo',
    home: 'Accueil',
    menu: 'Menu',
    nav: {
        start_project: 'Commencer un projet'
    },
    not_found : {
        button: 'Aller vers l\'accueil',
        text: 'Nous n\'avons pas pu trouver la page que vous cherchez. Elle semblerait avoir été supprimée ou déplacée ailleurs. Ne vous inquiétez pas! Utilisez le menu pour vous revenir sur la bonne voie et explorer nos services.',
        title: 'Oops! Il semblerait que vous soyez perdu.'
    },
    play_icon: 'jouer',
    since: 'Depuis',
    skip: 'Sauter au contenu',
    solutions: {
        link: 'Voir les projets en lien',
        projects: 'Projets en lien',
        project_before: 'Projet',
        project_after: '',
        slugs: {
            'social-media': 'medias-sociaux',
            'live-events': 'evenementiel',
            corporate: 'corporatif'
        }
    },
}
