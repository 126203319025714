<template>
    <div class="navbar navbar-mb"  data-lenis-prevent>
        <div class="navbar-mb__logo-menu --width-100-padding">
            <LangRouterLink :to="{ name: 'home' }" class="nav-logo">
                <Icon id="logo" />
            </LangRouterLink>
            <button :class="['navbar-mb__menu-btn', { '--open': isMenuOpen }]" @click="isMenuOpen = !isMenuOpen">
                {{ $t('menu') }}
                <div class="menu-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
        </div>
        <div :class="['navbar-mb__links', '--width-100-padding', { '--open': isMenuOpen }]">
            <div class="navbar-mb__buttons">
                <SiteLink class="--clear-bg" :to="{ name: $route.name ?? 'home', query: { contact: true } }"
                    :label="$t('nav.start_project')" />
                <LangSwitchLink class="lang__button" /> 
            </div>
            <div class="navbar__link-container" v-for="(menuItem, menuItemIndex) in $store.state.navigation.main" :key="`menu-${menuItemIndex}`">
                <LangRouterLink :to="menuItem.to" class="navbar-mb__link navbar__link h2"><span class="nav-hover"></span>{{
                    menuItem.label }}</LangRouterLink>
                <div class="navbar-mb__sub-menu navbar__sub-menu" v-if="menuItem.subMenu">
                    <LangRouterLink  class="navbar-mb__sub-menu-link navbar__sub-menu-link" :to="subMenuItem.to" v-for="(subMenuItem, subMenuItemIndex) in menuItem.subMenu" :key="`menu-${menuItemIndex}-${subMenuItemIndex}`"><span class="nav-hover"></span>{{ subMenuItem.label }}</LangRouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { LangRouterLink } from '@/components/global'
import { SiteLink } from '@/components/small'
import { bodyHelpers } from '@/helpers'
import { LangSwitchLink } from '@/components/navigation'

export default {
    name: 'MobileNav',
    components: {
        LangRouterLink,
        SiteLink,
        LangSwitchLink
    },
    computed: {
        otherLang() {
            return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
        }
    },
    data() {
        return {
            isMenuOpen: false
        }
    },
    watch: {
        isMenuOpen() {
            this.isMenuOpen
            this.isMenuOpen ? bodyHelpers.lockBody() : bodyHelpers.unlockBody()
        },
        '$route'() {
            if (this.isMenuOpen) this.isMenuOpen = false
        }
    },
}
</script>