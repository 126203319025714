import { createRouter, createWebHistory } from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound.vue')

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/a-propos',
        paths: { en: '/about' },
        name: 'about',
        component: () => import('@/views/About.vue')
    },
    {
        path: '/conditions-dutilisation',
        paths: { en: '/terms-of-use' },
        name: 'terms',
        component: () => import('@/views/Terms.vue')
    },
    {
        path: '/services/:slug',
        paths: { en: '/services/:slug' },
        name: 'solutions',
        props: true,
        component: () => import('@/views/Solutions.vue')
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

export default createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes,
    scrollBehavior(to, from) {
        document.activeElement.blur()

        if (to.hash) {
            return { el: to.hash, behavior: 'smooth', top: 100 }
        }

        if (to.path !== from.path) {
            document.activeElement.blur()
            return { top: 0, behavior: 'smooth' }
        }

        if (to.query !== from.query) {
            return false
        }
        
        return { left: 0, top: 0 }
    }
})
