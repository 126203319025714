import './startup.js'

import { createApp } from 'vue'

import '@/styles/app.scss'
import App from './App.vue'
import i18n from '@/i18n'
import store from '@/store'
import router from '@/router'

import langRouter from '@/extensions/langRouter'
import reveal from '@/extensions/reveal'
import GlobalComponents from '@/components/global'
import BeetAPI from '@boite-beet/api-client'

import { Vue3Mq } from 'vue3-mq'
import VueGtag from 'vue-gtag'
import mitt from 'mitt'

/* --- EXTENSIONS --- */
const app = createApp(App)
	.use(i18n)
	.use(store)
	.use(router)
	.use(langRouter, 'fr')
	.use(reveal)
	.use(GlobalComponents)
	.use(BeetAPI, { store, apiUrl: import.meta.env.VITE_APP_API_URL })
	.use(Vue3Mq, {
		breakpoints: {
			sm: 0,
			md: 950,
			lg: 1200
		}
	})

/* --- EVENT BUS --- */
const emitter = mitt()
app.provide('emitter', emitter)

/* --- GOOGLE ANALYTICS --- */
app.use(VueGtag, {
	bootstrap: false,
	config: { id: import.meta.env.VITE_APP_GTAG_ID },
}, router)


/* --- GOOGLE RECAPTCHA --- */
import { VueReCaptcha } from 'vue-recaptcha-v3'
app.use(VueReCaptcha, { siteKey: import.meta.env.VITE_APP_RECAPTCHA_KEY })


/* --- MOUNT --- */
app.mount('#app')
