<template>
    <RouterLink @click="bodyHelpers.unlockBody()" :to="to" :exact="exact">
        {{ this.toLang.toUpperCase() }}
    </RouterLink>
</template>

<script>
import { bodyHelpers, solutionsSlugTranslator } from '@/helpers'
import i18n from '@/i18n'

export default {
    name: 'LangSwitchLink',
    props: {
        exact: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        bodyHelpers() {
            return bodyHelpers
        },
        to() {
            const routeName = this.$route.name ?? 'home'
            return {
                name: this.toLang !== this.$i18n.fallbackLocale ? `${routeName}:${this.toLang}` : routeName.substring(0, routeName.indexOf(':')),
                params: this.storeObjectKey ? this.getTranslatedParams(this.$route.params, this.storeObjectKey) : this.$route.params,
                query: this.storeObjectKey ? this.getTranslatedParams(this.$route.query) : this.$route.query
            }
        },
        storeObjectKey() {
            const routeName = this.$route.name ?? 'home'
            if (routeName.includes('solution') || routeName.includes('projects')) return 'solutions'
            if (routeName.includes('project')) return 'projects'
            if (routeName.includes('post')) return 'posts'
            if (routeName.includes('contact')) return 'contact'
            return null
        },
        toLang() {
            return this.$i18n.availableLocales.filter(lang => lang !== this.$i18n.locale)[0]
        }
    },
    methods: {
        getTranslatedParams(paramType, storeObjectKey = null) {
            return Object.entries(paramType).reduce((newParamObj, [paramKey, paramValue]) => {
                if (paramKey === 'pathMatch') return { [paramKey]: paramValue }
                return { ...newParamObj, ...this.paramTranslator(paramKey, paramValue, storeObjectKey) }
            }, {})
        },
        paramTranslator(paramKey, paramValue, storeObjectKey = null) {
            if (storeObjectKey === 'solutions' && paramKey === 'job') return null
            if (storeObjectKey === 'solutions' && paramKey === 'slug') {
                const slugKey = solutionsSlugTranslator(paramValue)
                return { [paramKey]: i18n.global.messages.value[this.toLang].solutions.slugs[slugKey] }
            }

            const elementId = this.$store.getters[`${this.storeObjectKey}SlugMap`][paramValue]
            const { slugs = {} } = this.$beet[`${this.storeObjectKey}`][elementId] || {}
            return elementId ? { [paramKey]: slugs[this.toLang] } : { [paramKey]: paramValue }
        }
    }
}
</script>