export default {
    button: 'Send the Message',
    description: 'We can adapt our offer to your budget. Help us understand your needs by completing this form and we will be happy to discuss it with you!',
    errors: {
        company_name: 'Please avoid using special characters.',
        email: 'Please enter a valid email address.',
        full_name: 'Please avoid using special characters.',
        short_description: 'The text field must not be empty and contain 255 characters at most.',
        solution_id: 'Please choose a project type.',
    },
    labels: {
        company_name: 'Company Name',
        email: 'Your Email',
        full_name: 'Your Full Name',
        short_description: 'Brief Description of Your Project',
        solution_id: 'Type of Project',
        solution_id_placeholder: 'Select a type'
    },
    title: 'Let\'s discuss your project',
    confirmation: {
        title: 'Your message has been succesfully sent!',
        text: 'A member of our team will contact you shortly to discuss your ideas.',
        button: 'Close Window'
    },
    sendingError: 'A problem came up while sending the form.'
}