<template>
    <div class="video-modal">
        <SiteLink class="modal-video__button-close --no-style" icon="close" :to="{ name: $route.name ?? 'home' }"
            label="" />
        <span class="vimeo-loader"></span>
        <iframe loading="lazy" mozallowfullscreen webkitallowfullscreen allowfullscreen
            :src="`https://player.vimeo.com/video/${$route.query.v}?h=40b89b49ac?background=1&autoplay=1&quality=1080p&muted=1`"
            width="640" height="360" frameborder="0">
        </iframe>
    </div>
</template>

<script>
import SiteLink from '@/components/small/SiteLink.vue'

export default {
    name: 'VideoModal',
    components: {
        SiteLink
    }
}
</script>