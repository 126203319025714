<template>
    <div class="form__field" :class="{ '--medium': medium }">
        <label :for="name" class="h3">{{ label }}</label>
        <input v-if="type !== 'textarea'" :type="type" :id="name" :name="name" @input.passive="input"
            @focusout="onFocus = false" :value="modelValue.value" />
        <textarea v-if="type === 'textarea'" :id="name" :name="name" @input.passive="input" @focusout="onFocus = false"
            :value="modelValue.value" />
        <p :class="['input-error', { '--hidden': (modelValue.valid || onFocus) }]">
            {{ $t(`form.errors.${name}`) }}
        </p>
    </div>
</template>

<script>
import { REGEX } from '@/helpers/regex.js'

export default {
    name: 'FormInput',
    props: {
        label: {
            type: String,
            required: true
        },
        modelValue: {
            type: Object,
            required: true
        },
        medium: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text',
            validator: value => ['email', 'text', 'textarea'].includes(value)
        }
    },
    data() {
        return {
            onFocus: true
        }
    },
    methods: {
        input(event) {
            this.$emit('update:modelValue', {
                value: event.target.value,
                valid: this.validate(event.target.value)
            })
        },
        validate(value) {
            switch (this.name) {
                case 'email':
                    return REGEX.EMAIL.test(value)
                case 'company_name':
                case 'full_name':
                    return REGEX.NAME.test(value)
                default:
                    return REGEX.TEXT_SYMBOLS.test(value)
            }
        }
    },
    emits: ['update:modelValue']
}
</script>