import {createI18n} from 'vue-i18n'

import enDefault from '@/i18n/en/default.js'
import enForm from '@/i18n/en/form.js'
import enGDPR from '@/i18n/en/gdpr.js'

import frDefault from '@/i18n/fr/default.js'
import frForm from '@/i18n/fr/form.js'
import frGDPR from '@/i18n/fr/gdpr.js'

export default createI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: {
        en: {
            ...enDefault,
            form: enForm,
            gdpr: enGDPR
        },
        fr: {
            ...frDefault,
            form: frForm,
            gdpr: frGDPR
        }
    }
})
