export const REGEX = {
	EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
	NAME: /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,255}$/,
	NUMBER: /^\d+$/,
	PHONE: /^\d{10}$/,
	SIX_DIGITS: /^\d{6}$/,
	STRING_WITH_NUMBERS: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,255}$/,
	TEXT: /^[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,1000}$/,
    TEXT_SYMBOLS: /^[^_¡÷¿/\\+=@#%ˆ&*{}|~<>[\]]{1,255}$/
}
