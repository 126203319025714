export default {
    button: 'Envoyer le message',
    description: 'Nous pouvons nous adapter à votre budget. Aidez-nous à comprendre vos besoins en remplissant ce formulaire et nous serons heureux de discuter avec vous!',
    errors: {
        company_name: 'Veuillez éviter d\'utiliser des caractères spéciaux.',
        email: 'Veuillez entrer une adresse courriel valide.',
        full_name: 'Veuillez éviter d\'utiliser des caractères spéciaux.',
        short_description: 'Le champ texte ne doit pas être vide et doit contenir au maximum 255 caractères.',
        solution_id: 'Veuillez choisir un type de projet.',
    },
    labels: {
        company_name: 'Nom de la compagnie',
        email: 'Votre courriel',
        full_name: 'Votre nom complet',
        short_description: 'Briève description de votre projet',
        solution_id: 'Type de projet',
        solution_id_placeholder: 'Sélectionnez un type'
    },
    title: 'Parlez-nous de votre projet',
    confirmation: {
        title: 'Votre message a été envoyé avec succès!',
        text: 'Un membre de notre équipe vous contactera sous peu afin de discuter de vos idées.',
        button: 'Fermer la fenêtre'
    },
    sendingError: 'Un problème est survenu lors de l\'envoi du formulaire.'
}