<template>
    <a class="skip-to-content-link" href="#main" :title="$t('skip')">
        {{ $t('skip') }}
    </a>
</template>

<script>
export default {
    name: 'SkipToContent'
}
</script>