<template>
    <div class="infinite-text-slider-container">
        <div ref="container" class="infinite-text-slider" :style="`--scroll-text-margin: ${textMargin / 16}rem`">
            <p class="rolling-text"><span>{{ content }}</span></p>
            <p class="rolling-text"><span>{{ content }}</span></p>
            <p class="rolling-text"><span>{{ content }}</span></p>
            <p class="rolling-text"><span>{{ content }}</span></p>
            <p class="rolling-text"><span>{{ content }}</span></p>
        </div>
    </div>
</template>

<script>
import { HasContentMixin } from '@/mixins'

export default {
    name: 'ScrollingText',
    mixins: [HasContentMixin],
    data() {
        return {
            defaultSpeed: 6,
            speed: 6,
            defaultPosition: 0,
            position: 0,
            lastScrollY: window.scrollY,
            textMargin: 10,
            forwardMoveSpeed: 1.5,
            backwardMoveSpeed: -3
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.move()
        })
        window.addEventListener('scroll', this.handleScroll, { passive: true })
        this.mq.md ? this.defaultSpeed = 4 : this.defaultSpeed = 2
        this.mq.md ? this.speed = 4 : this.speed = 2
        this.mq.md ? this.forwardMoveSpeed = 1.5 : this.forwardMoveSpeed = 0.5
        this.mq.md ? this.backwardMoveSpeed = -2 : this.backwardMoveSpeed = -0.5
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        move() {
            if (this.$refs.container) {
                const textWidth = this.$refs.container.children[0].offsetWidth
                this.defaultPosition = textWidth

                this.position -= this.speed
                this.$refs.container.style.transform = `translateX(${this.position}px)`

                // * -3 
                // so even if the text winds back a lot when scrolling,
                // we don't see the beginning of the loop
                if (this.position <= ((-3 * textWidth) - this.textMargin)) {
                    this.position = -this.defaultPosition
                }
                requestAnimationFrame(this.move)
            }
        },
        handleScroll() {
            const currentScrollY = window.scrollY
            this.speed += currentScrollY > this.lastScrollY ? this.forwardMoveSpeed : this.backwardMoveSpeed

            setTimeout(() => {
                this.speed = this.defaultSpeed
            }, 200)

            this.lastScrollY = currentScrollY
        }
    },
    inject: ['mq']
}
</script>