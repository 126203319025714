export default {
    about: 'About',
    beet: {
        credits: 'Website created by'
    },
    browser_support: 'Your browser may not support this.',
    featured_projects_1: 'See all ',
    featured_projects_2: 'projects',
    footer: {
        talk_to_expert: 'Talk to an expert',
        follow_us: 'Follow us',
        facebook: 'Facebook Link',
        instagram: 'Instagram Link',
        linkedin: 'LinkedIn Link',
        vimeo: 'Vimeo Link',
        rights: '©[YEAR] Beet Productions. All rights reserved. ',
        terms_and_conditions: 'Terms and Conditions',
        dev: 'Website designed and developed by '
    },
    full_video: 'Play the video',
    home: 'Home',
    menu: 'Menu',
    nav: {
        start_project: 'Start a project'
    },
    not_found : {
        button: 'Visit Homepage',
        text: 'We couldn\'t find the page you\'re looking for. It seems to have been deleted or moved elsewhere. But don\'t fret! Use the menu to get back on track and explore our services.',
        title: 'Oops! Seems you\'re lost.'
    },
    play_icon: 'play',
    since: 'Since',
    skip: 'Skip to content',
    solutions: {
        link: 'View related projects',
        projects: 'Related Projects',
        project_before: '',
        project_after: 'Project',
        slugs: {
            'social-media': 'social-media',
            'live-events': 'live-events',
            corporate: 'corporate'
        }
    },
}
