<template>
    <div class="navbar navbar-dk --width-100-padding">
        <LangRouterLink :to="{ name: 'home' }" class="nav-logo">
            <Icon id="logo" />
        </LangRouterLink>
        <div class="navbar-dk__links">
            <div class="navbar__link-container" v-for="(menuItem, menuItemIndex) in $store.state.navigation.main"
                :key="`menu-${menuItemIndex}`">
                <LangRouterLink :to="menuItem.to" class="navbar-dk__link navbar__link">
                    <span class="nav-hover"></span>
                    {{ menuItem.label }}
                </LangRouterLink>
                <div class="navbar-dk__sub-menu-container">
                    <div class="navbar-dk__sub-menu navbar__sub-menu" v-if="menuItem.subMenu">
                        <LangRouterLink class="navbar-dk__sub-menu-link navbar__sub-menu-link" :to="subMenuItem.to"
                            v-for="(subMenuItem, subMenuItemIndex) in menuItem.subMenu"
                            :key="`menu-${menuItemIndex}-${subMenuItemIndex}`"><span class="nav-hover"></span>
                            {{ subMenuItem.label }}
                        </LangRouterLink>
                    </div>
                </div>
            </div>
            <LangSwitchLink class="lang__button" />
            <SiteLink class="--clear-bg" :to="{ name: $route.name ?? 'home', query: { contact: true } }"
                :label="$t('nav.start_project')" />
        </div>
    </div>
</template>

<script>
import { LangRouterLink } from '@/components/global'
import { SiteLink } from '@/components/small'
import { LangSwitchLink } from '@/components/navigation'

export default {
    name: 'DesktopNav',
    components: {
        LangRouterLink,
        SiteLink,
        LangSwitchLink
    },
    computed: {
        otherLang() {
            return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
        }
    }
}
</script>