<template>
    <footer class="footer" v-reveal :key="$route.path">
        <ScrollingText class="rolling-title" id="rolling-text" :content="$beet.options.footer.title" />
        <div class="footer__content --width-100-padding">
            <SiteLink class="footer__talk-expert animated-pop" :label="$t('footer.talk_to_expert')" :to="{ name: $route.name ?? 'home', query: { contact: true } }" />
            <div class="footer__office" v-for="office in $beet.options.footer.offices" :key="office.flexKey">
                <AnimatedText :text="office.name" :classes="['footer__office-title', 'h3']" down="down" type="h2" />
                <a class="footer__office-link" :href="office.addressLink" target="_blank">{{ office.addressName }}</a>
                <a class="footer__office-link" :href="`tel:${office.phone.replace(/\D/g, '')}`">{{ office.phone }}</a>
            </div>
            <div class="footer__medias">
                <AnimatedText :text="$t('footer.follow_us')" :classes="['footer__medias-title', 'h3']" down="down" type="h2" />
                <a v-if="$beet.options.socials.facebook" class="footer__medias-link" id="footer-facebook-link" :href="$beet.options.socials.facebook" target="_blank" :aria-label="$t('footer.facebook')"><Icon id="facebook"/></a>
                <a v-if="$beet.options.socials.instagram" class="footer__medias-link" id="footer-inastagram-link" :href="$beet.options.socials.instagram" target="_blank" :aria-label="$t('footer.instagram')"><Icon id="instagram"/></a>
                <a v-if="$beet.options.socials.linkedin" class="footer__medias-link" id="footer-linkedin-link" :href="$beet.options.socials.linkedin" target="_blank" :aria-label="$t('footer.linkedin')"><Icon id="linkedin"/></a>
                <a v-if="$beet.options.socials.vimeo" class="footer__medias-link" id="footer-vimeo-link" :href="$beet.options.socials.vimeo" target="_blank" :aria-label="$t('footer.vimeo')"><Icon id="vimeo"/></a>
            </div>
        </div>
        <div class="footer__credits --width-100-padding">
            <p>{{ $t('footer.rights').replace('[YEAR]', new Date().getFullYear()) }} | <SiteLink class="footer__terms-link --no-style" :to="{ name: 'terms' }" :label="$t('footer.terms_and_conditions')" icon="no" /></p>
            <p>{{ $t('footer.dev') }}<a class="beet --bold" href="https://boitebeet.com/" target="_blank">Beet</a></p>        
        </div>
    </footer>
</template>

<script>
import { SiteLink, AnimatedText } from '@/components/small'
import { ScrollingText } from '@/components/small'

export default {
    name: 'SiteFooter',
    components: {
        SiteLink,
        ScrollingText,
        AnimatedText
    }
}
</script>