import { createStore } from 'vuex'
import {navigation} from '@/store/navigation'

const createSlugMap = typeKey => ({ beet }) => {
    const data = beet.data[typeKey] || {}
    return Object.values(data).reduce((slugMap, { id, slug }) => ({ ...slugMap, [slug]: id }), {})
}

export default createStore({
    getters: {
        solutionsSlugMap: createSlugMap('solutions'),
    },
    modules: {
        navigation
    }
})
