<template>
    <DesktopNav v-if="mq.lg" />
    <MobileNav v-if="!mq.lg" />
</template>

<script>
import { DesktopNav } from '@/components/navigation'
import { MobileNav } from '@/components/navigation'

export default {
	name: 'SiteNav',
	components: {DesktopNav, MobileNav},
	computed: {
		otherLang() {
			return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
		}
	},
    data() {
        return {
            headerHeight: 600,
            navbar: null
        }
    },
    mounted() {
        this.navbar = document.querySelector('.navbar')
        window.addEventListener('scroll', this.menuFixed)
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.menuFixed)
    },
    methods: {
        menuFixed() {
            if(window.scrollY >= this.headerHeight) {
                if(!this.navbar.classList.contains('fixed-top')) {
                    this.navbar.classList.add('fixed-top')
                }
            }
            else {
                if(this.navbar.classList.contains('fixed-top')) {
                    this.navbar.classList.remove('fixed-top')
                }
            }
            if(window.scrollY < this.headerHeight && window.scrollY >= 400) {
                if(!this.navbar.classList.contains('height-0')) {
                    this.navbar.classList.add('height-0')
                }
            }
            else {
                if(this.navbar.classList.contains('height-0')) {
                    this.navbar.classList.remove('height-0')
                }
            }
        }
    },
    inject: ['mq']
}
</script>
