<template>
    <Transition name="exit-top">
        <aside @click="close" class="modal" v-if="opened" data-lenis-prevent>
            <InquiryForm v-if="$route.query.contact" ref="form" @click.stop />
            <VimeoVideo v-if="$route.query.v" ref="vimeo" @click.stop />
        </aside>
    </Transition>
</template>

<script>
import { InquiryForm, VimeoVideo } from '@/components/modal/blocks'
import { bodyHelpers } from '@/helpers'

export default {
    name: 'Modal',
    components: {
        InquiryForm,
        VimeoVideo
    },
    computed: {
        opened() {
            return this.$route.query.v || this.$route.query.contact
        }
    },
    watch: {
        opened() {
            this.opened ? bodyHelpers.lockBody() : bodyHelpers.unlockBody()
        }
    },
    methods: {
        close() {
            this.$router.push({ ...this.$route, query:null })
        }
    },
    emits: ['modal-closed', 'modal-opened']
}
</script>