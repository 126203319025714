<template>
    <div v-if="appReady">
        <SkipToContent />
        <SiteNav />
        <RouterView />
        <Gdpr />
        <SiteFooter />
        <Modal @modal-closed="startLenis" @modal-opened="stopLenis" />
    </div>
</template>

<script>
import { useLangRouter } from '@/extensions/langRouter'
import { SiteNav } from '@/components/navigation'
import Gdpr from './components/gdpr/Gdpr.vue'
import { SiteFooter } from '@/components/sections'
import Modal from '@/components/modal/Modal.vue'
import Lenis from 'lenis'
import { SkipToContent } from '@/components/small'

export default {
    name: 'App',
    setup() {
        useLangRouter()
    },
    components: {
        Gdpr,
        Modal,
        SiteFooter,
        SiteNav,
        SkipToContent,
    },
    data() {
        return {
            appReady: false,
        }
    },
    watch: {
        '$route.path': {
            handler() {
                this.scrollToPosition()
            },
            immediate: true
        },
        '$i18n.locale': {
            handler: async function (to) {
                await this.$beet.fetchCoreData(to)
                await this.$store.dispatch('initMenu', Object.values(this.$beet.solutions))
                this.appReady = true
            },
            immediate: true
        }
    },
    mounted() {
        window.lenis = new Lenis()

        const raf = (time) => {
            window.lenis.raf(time)
            requestAnimationFrame(raf)
        }

        requestAnimationFrame(raf)
    },
    updated() {
        this.scrollToPosition()
    },
    methods: {
        stopLenis() {
            window.lenis.stop()
        },
        startLenis() {
            window.lenis.start()
        },
        scrollToPosition() {
            this.$nextTick(() => {
                const el = this.$route.hash ? document.querySelector(this.$route.hash) : null
                if (el) {
                    window.lenis.scrollTo(el, {offset: -100})
                } else {
                    window.lenis.scrollTo(0, { immediate: true })
                }
            })
        }
    }
}
</script>