import { reactive } from 'vue'

export const bodyHelpers = reactive({
    lockCount: 0,

    lockBody() {
        bodyHelpers.lockCount++
        if (bodyHelpers.lockCount > 0) {
            document.body.classList.add('--lock')
            window.lenis.stop()
        }
    },

    unlockBody() {
        bodyHelpers.lockCount--
        if (bodyHelpers.lockCount <= 0) {
            document.body.classList.remove('--lock')
            bodyHelpers.lockCount = 0
            window.lenis.start()
        }
    }
})