<template>
    <div class="form">
        <div class="modal__button-container">
            <SiteLink class="form__button-close --no-style" icon="close" :to="{ name: $route.name ?? 'home' }"
                label="" />
        </div>
        <div class="form__container" ref="form">
            <h2 class="form__title">{{ $t('form.title') }}</h2>
            <p>{{ $t('form.description') }}</p>
            <form @submit.prevent="submit" :class="{ '--hidden': form.server.valid }">
                <Transition name="fade">
                    <p v-if="form.server.error" class="form__error h3">{{ form.server.error }}</p>
                </Transition>
                <Transition name="fade">
                    <Loader v-if="form.submitted" />
                </Transition>
                <FormSelect :label="$t('form.labels.solution_id')" medium name="solution_id" :options="solutionOptions"
                    :placeholder="$t('form.labels.solution_id_placeholder')" v-model="form.fields.solution_id" />
                <FormInput :label="$t('form.labels.company_name')" medium name="company_name"
                    v-model="form.fields.company_name" />
                <FormInput :label="$t('form.labels.full_name')" medium name="full_name"
                    v-model="form.fields.full_name" />
                <FormInput :label="$t('form.labels.email')" medium name="email" type="email"
                    v-model="form.fields.email" />
                <FormInput :label="$t('form.labels.short_description')" name="short_description" type="textarea"
                    v-model="form.fields.short_description" />

                <button class="beet-link" type="submit" id="form-submit" :disabled="!form.valid"
                    @click="$refs.form.scrollIntoView()">
                    <span>{{ $t('form.button') }}</span>
                    <Icon id="arrow" />
                </button>
            </form>
            <div :class="['form__confirmation', { '--hidden': !form.server.valid }]">
                <Icon id="confirmation" class="form__confirmation-icon" />
                <h3>{{ $t('form.confirmation.title') }}</h3>
                <p>{{ $t('form.confirmation.text') }}</p>
                <SiteLink :to="{ name: $route.name ?? 'home' }" :label="$t('form.confirmation.button')" icon="no" />
            </div>
        </div>
    </div>
</template>

<script>
import { FormInput, FormSelect } from '@/components/form/fields'
import { SiteLink, Loader } from '@/components/small'
import http from '@/extensions/http'

export default {
    name: 'InquiryForm',
    components: {
        FormInput,
        FormSelect,
        Loader,
        SiteLink
    },
    computed: {
        solutionOptions() {
            return Object.values(this.$beet.solutions).map(solution => {
                return { key: solution.id, value: solution.name }
            })
        }
    },
    data() {
        return {
            form: {
                fields: {
                    company_name: {
                        value: '',
                        valid: false
                    },
                    email: {
                        value: '',
                        valid: false
                    },
                    full_name: {
                        value: '',
                        valid: false
                    },
                    short_description: {
                        value: '',
                        valid: false
                    },
                    solution_id: {
                        value: '',
                        valid: false
                    },
                },
                server: {
                    error: null,
                    submitted: false,
                    valid: false
                },
                valid: false,
                submitted: false
            }
        }
    },
    watch: {
        'form': {
            handler() {
                this.form.valid = !Object.values(this.form.fields).some(({ valid }) => valid === false)
            },
            deep: true
        }
    },
    methods: {
        async submit() {
            if (this.form.valid) {
                try {
                    this.form.submitted = true
                    const formData = new FormData()
                    Object.entries(this.form.fields).forEach(([key, field]) => {
                        formData.append(key, field.value)
                    })

                    await this.$recaptcha()

                    this.form.server.submitted = true

                    await http.post('/inquiries', formData)

                    this.form.server.valid = true
                }
                catch (exception) {
                    this.form.submitted = false
                    if (exception.response) {
                        this.form.server.error = exception.response.data
                    }
                    else {
                        this.form.server.error = this.$t('form.sendingError')
                    }
                }
            }
        }
    }
}
</script>