import i18n from '@/i18n'

export const navigation = {
    state: {
        main: null
    },
    mutations: {
        initMenu(state, solutions) {
            const navigation = []

            solutions.forEach(solution => {
                const solutionRoute = {name: 'solutions', params: {slug: solution.slug}}
                let subMenu = null

                if(solution.sections) {
                    subMenu = solution.sections.map(section => {
                        return {label: section.title, to: {...solutionRoute, hash: `#${section.anchor}`}}
                    })
                }

                navigation.push({label: solution.name, subMenu: subMenu, to: solutionRoute})
            })

            navigation.push({label: i18n.global.t('about'), to: {name: 'about'}})
            
            state.main = navigation
        }
    },
    actions: {
        initMenu(context, solutions) {
            context.commit('initMenu', solutions)
            return true
        }
    }
}