<template>
    <component :is="type" :class="`animated-title ${stringifyClasses} ${down}`" ref="title" v-reveal>
        {{ text }}
    </component>
</template>

<script>

export default {
    name: 'AnimatedText',
    props: {
        type: {
            type: String,
            required: true,
            validator: value => ['h1', 'h2', 'h3', 'p'].includes(value)
        },
        classes: {
            type: Array,
            default: null
        },
        text: {
            type: String,
            required: true
        },
        down: {
            type: String,
            default: ''
        }
    },
    computed: {
        stringifyClasses() {
            if (this.classes) {
                return this.classes.join(' ')
            }
            return ''
        },
    },
    watch: {
        'text'(newText, oldText) {
            if (newText !== oldText) {
                this.animate()
            }
        }
    },
    mounted() {
        this.animate()
    },
    methods: {
        animate() {
            const title = this.$refs.title
            const titleText = this.text

            title.innerHTML = ''

            const words = titleText.split(' ')

            words.forEach((word, index) => {
                const span = document.createElement('span')
                span.innerText = word
                span.style.transitionDelay = `${index * 0.05}s`
                title.appendChild(span)
            })
        }
    }
}
</script>