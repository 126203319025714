<template>
    <div class="form__field" :class="{ '--medium': medium }">
        <label :for="name" class="h3">{{ label }}</label>
        <select :id="name" :name="name" @change="change">
            <option disabled selected value="0">{{ placeholder }}</option>
            <option v-for="option in options" :key="option.key" :value="option.key"
                :selected="modelValue.value === option.key">
                {{ option.value }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'FormSelect',
    props: {
        label: {
            type: String,
            required: true
        },
        modelValue: {
            type: Object,
            required: true
        },
        medium: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        }
    },
    methods: {
        change(event) {
            this.$emit('update:modelValue', {
                value: event.target.value,
                valid: event.target.value && event.target.value !== '0'
            })
        }
    }
}
</script>